import { lazy, Suspense, useEffect } from "react";
import RequireAuth from "./features/RequireAuth";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./features/login/Login";
import Layout from "./features/Layout";
import PersistLogin from "./features/PersistLogin";
import RequireCompany from "./features/RequireCompany";
import Loading from "./components/loading/Loading";
import CheckToken from "./features/CheckToken";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Skeleton = lazy(() => import("./features/skeleton/Skeleton"));
const ConfigStation = lazy(() =>
  import("./features/master/configstation/ConfigStation")
);
const InvalidURL = lazy(() => import("./components/ErrorPages/InvalidURL"));
const BackendResponse = lazy(() =>
  import("./components/ErrorPages/BackendResponse")
);
const MaintenanceMode = lazy(() =>
  import("./components/ErrorPages/MaintenanceMode")
);

function App() {
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const manifestUrl = "/manifest.json";

    axios
      .get(manifestUrl, { signal })
      .then((response) => {
        const data = response.data;
        document.title = data?.name;
      })
      .catch((error) => {
        if (error.code !== "ERR_CANCELED") {
          console.error("Error in setting app title:", error);
        }
      });

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public route */}
          <Route element={<CheckToken />}>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/login" replace={true} />} />
          </Route>

          {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
          {/* <Route path="/home" element={<Home />} /> */}

          {/* <Route path="/unauthorized" element={<Unauthorized />}></Route> */}
          <Route
            path="/backend_response"
            element={
              <Suspense fallback={<Loading />}>
                <BackendResponse />
              </Suspense>
            }
          ></Route>
          <Route
            path="/maintenance_mode"
            element={
              <Suspense fallback={<Loading />}>
                <MaintenanceMode />
              </Suspense>
            }
          ></Route>

          {/* protected route */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireCompany />}>
              <Route
                element={
                  <RequireAuth allowedRoles={["Master", "Admin", "User"]} />
                }
              >
                <Route
                  path="/dashboard/*"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Skeleton />
                    </Suspense>
                  }
                />
              </Route>
            </Route>

            <Route
              element={
                <RequireAuth allowedRoles={["Master", "Admin", "User"]} />
              }
            >
              <Route
                path="/config/*"
                element={
                  <Suspense fallback={<Loading />}>
                    <ConfigStation />
                  </Suspense>
                }
              />
            </Route>
          </Route>

          {/* Catch all */}
          <Route
            path="*"
            element={
              <Suspense fallback={<Loading />}>
                <InvalidURL />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
