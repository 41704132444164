import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const loginSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, actions) => {
      return actions.payload;
    },
    resetAuth: (state) => {
      return {};
    },
    setCompanyId: (state, actions) => {
      state.company_name_pk = actions.payload;
    },
    setLan: (state, actions) => {
      state.lan = actions.payload;
    },
  },
});

export const { setAuthUser, resetAuth, setCompanyId, setLan } =
  loginSlice.actions;

export default loginSlice.reducer;
